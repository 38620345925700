import { Controller } from '@hotwired/stimulus'
import { v4 as uuid } from 'uuid'

export default class extends Controller {
  static targets = ['loadingElement']
  static values = {
    selector: String,
  }

  initialize() {
    this.insert = this.insert.bind(this)
  }

  connect() {
    this.uniqueId = `spinner-${uuid()}`
    this.spinnerHTML = `<div id='${this.uniqueId}' class='loading'><i class='fas fa-spinner-third fa-spin'></i></div>`

    this.getLoadingElement.classList.add('overlay-spinner')
  }

  disconnect() {
    if (!this.getLoadingElement) {
      return
    }
    this.remove()

    this.getLoadingElement.classList.remove('overlay-spinner')
  }

  insert() {
    if (!this.spinnerElement())
      this.getLoadingElement.insertAdjacentHTML('beforeend', this.spinnerHTML)
    this.getLoadingElement.classList.add('overlay-spinner-active')
  }

  remove() {
    const spinnerElement = this.spinnerElement()
    if (spinnerElement) spinnerElement.remove()

    this.getLoadingElement.classList.remove('overlay-spinner-active')
  }

  spinnerElement() {
    return document.getElementById(this.uniqueId)
  }

  get getLoadingElement() {
    if (this.hasLoadingElementTarget) {
      return this.loadingElementTarget
    } else if (this.hasSelectorValue) {
      return document.querySelector(this.selectorValue)
    } else {
      return this.element
    }
  }
}
